<template>
  <div
    v-if="showActiveTask"
    class="task-container"
  >
    <div class="task-card">
      <div
        v-if="!description"
        class="task-name"
      >
        {{ pokerSession.activeTask.description }}
      </div>
      <el-input
        v-if="description"
        v-model="pokerSession.activeTask.description"
        size="small"
        @change="updateTaskScore"
      />
      <div class="buttons-panel">
        <div
          v-if="organizer"
          class="buttons"
        >
          <el-button
            class="btn"
            type="success"
            plain
            @click="resetVotes"
          >
            <i
              class="el-icon-refresh-left"
            />
            Reset Cards
          </el-button>
          <el-button
            v-if="!firstTask"
            class="btn"
            type="primary"
            plain
            @click="showPreviousStory"
          >
            <i
              class="el-icon-arrow-left"
            />
            Previous
          </el-button>
          <el-button
            v-if="!lastTask"
            class="btn"
            type="primary"
            plain
            @click="showNextStory"
          >
            <i
              class="el-icon-arrow-right"
            />
            Next Story
          </el-button>
          <el-button
            v-if="(lastTask || !firstTask) && !firstTask"
            class="btn"
            type="primary"
            plain
            @click="showFirstStory"
          >
            <i
              class="el-icon-arrow-up"
            />
            First Story
          </el-button>
        </div>
        <div
          v-if="organizer"
          class="edit-story"
          @click="editDescription"
        >
          <span>
            <i
              class="el-icon-edit"
            />
            Edit Story
          </span>
        </div>
      </div>
    </div>
    <div class="score-timer-card">
      <div class="score-table">
        <div
          v-if="showScore"
          class="score"
        >
          <div v-if="!score">
            {{ pokerSession.activeTask.score }}
          </div>
          <el-input-number
            v-if="score"
            v-model="pokerSession.activeTask.score"
            size="small"
            @change="updateTaskScore"
          />
        </div>
        <div
          v-if="dontShowScore"
          class="score"
        >
          --
        </div>
        <div
          v-if="showEditScore"
          class="edit-score"
        >
          <span
            v-if="organizer"
            @click="editScore"
          >
            <i
              class="el-icon-edit"
            />
            Edit Score
          </span>
        </div>
      </div>
      <div class="timer">
        <div class="countdown">
          {{ countDown }}
        </div>
        <div
          v-if="organizer"
          class="pause-add-time"
        >
          <span class="time-btn">
            +1m
          </span>
          <span class="time-btn">
            Pause
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { showError } from '../../../notification';

export default {
  name: 'DisplayRow',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      countDown: '00:59',
      openedCards: false,
      closedCards: false,
      score: false,
      description: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    showActiveTask() {
      return this.pokerSession.exists() && ((this.pokerSession.activeTask !== undefined) && (this.pokerSession.activeTask !== null));
    },
    showScore() {
      return this.pokerSession.options.showScore || this.organizer;
    },
    showEditScore() {
      return this.pokerSession.options.showScore || this.organizer;
    },
    dontShowScore() {
      return !this.pokerSession.options.showScore && !this.organizer;
    },
    firstTask() {
      return this.pokerSession.tasks.length ? this.pokerSession.activeTask.uuid === this.pokerSession.tasks[0].uuid : false;
    },
    lastTask() {
      return this.pokerSession.tasks.length ? this.pokerSession.activeTask.uuid === this.pokerSession.tasks[this.pokerSession.tasks.length - 1].uuid : false;
    },
    organizer() {
      if (this.pokerSession.exists()) {
        return this.pokerSession.user.uuid === this.user.uuid;
      }

      return true;
    },
  },
  methods: {
    showNextStory() {
      this.resetVotes();
      const index = this.pokerSession.tasks.findIndex(el => this.pokerSession.activeTask.uuid === el.uuid) + 1;

      this.pokerSession.activeTask = this.pokerSession.tasks[index];
      this.updateActiveTask();
    },
    showPreviousStory() {
      this.resetVotes();
      const index = this.pokerSession.tasks.findIndex(el => this.pokerSession.activeTask.uuid === el.uuid) - 1;

      this.pokerSession.activeTask = this.pokerSession.tasks[index];
      this.updateActiveTask();
    },
    showFirstStory() {
      this.resetVotes();
      [this.pokerSession.activeTask] = this.pokerSession.tasks;
      this.updateActiveTask();
    },
    editScore() {
      this.score = !this.score;
    },
    async updateTaskScore() {
      try {
        this.resetVotes();
        if (this.score) {
          this.editScore();
        }
        if (this.description) {
          this.editDescription();
        }
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },
    async updateActiveTask() {
      await this.pokerSession.update();
    },
    editDescription() {
      this.description = !this.description;
    },
    resetVotes() {
      this.$emit('reset-votes');
    },
  },
};
</script>

<style scoped>
  .task-container {
    display               : grid;
    grid-template-columns : 85% 15%;
    border: 1px solid #ebeef5;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }

  .score-timer-card {
    display            : grid;
    grid-template-rows : 65% 35%;
  }

  .score-table {
    display: grid;
    grid-template-rows: 80% 20%;
    padding: 10px;
  }

  .score {
    justify-self: center;
    align-self: center;
    font-size: 25px;
  }

  .edit-score {
    justify-self: center;
    cursor: pointer;
  }

  .task-name {
    margin: 10px;
    font-size : 14px;
  }

  .buttons-panel {
    background: #f4f4f5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .buttons {
    display: flex;
    align-self: stretch;
    padding: 10px;
  }
  .btn {
    flex-grow: 1;
    padding: 0 10px;
  }
  .time-btn {
    cursor: pointer;
  }
  .edit-story {
    cursor: pointer;
    padding: 10px;
  }
  .task-card {
    display            : grid;
    grid-template-rows : 65% 35%;
    border-right: 1px #ebeef5 solid
  }

  .timer {
    display : grid;
    grid-template-rows: 70% 30%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background: #f4f4f5;
  }

  .countdown {
    justify-self: center;
    align-self: center;
    font-size: 20px;
  }
  .pause-add-time {
    display: flex;
    width: 80%;
    justify-content: space-between;
    justify-self: center;
    align-self: center;
  }
</style>
